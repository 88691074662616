
import React from 'react'

export const Comments = () => {
  return (
    <div className='w-[1200px] text-[#444] mx-auto mt-14 min-h-[380px] flex flex-col items-center gap-12 '>
        <div>
            A trusted choice for over 5,000 freight dispatchers.
        </div>
        <div className='flex gap-5'>
            <div className='bg-gradient-to-tr from-gray-200 to-slate-300 w-[320px] min-h-[250px] flex flex-col gap-7 rounded-md py-8 p-6'>
                <div className='flex items-center gap-5'>
                    <div className='w-[48px] h-[48px]  bg-white rounded-full'>

                    </div>
                    <div className='text-[20px] font-semibold '>
                        Benjamin Foster
                    </div>
                </div>
                <div className='max-w-[250px]'>
                    I can't imagine managing our dispatch without Dispatch Buddy. It's a complete game-changer for our team. The user-friendly interface makes our work a breeze.
                </div>
            </div>
            <div className='bg-gradient-to-tr from-gray-200 to-slate-300 w-[320px] min-h-[250px] flex flex-col gap-7 rounded-md py-8 p-6'>
                <div className='flex items-center gap-5'>
                    <div className='w-[48px] h-[48px]  bg-white rounded-full'>

                    </div>
                    <div className='text-[20px] font-semibold '>
                        Olivia Mitchell
                    </div>
                </div>
                <div className='max-w-[250px]'>
                    What I love about Dispatch Buddy is how it streamlines the booking process. It's like having a personal assistant for load management. I've recommended it to all my industry peers!
                </div>
            </div>
            <div className='bg-gradient-to-tr from-gray-200 to-slate-300 w-[320px] min-h-[250px] flex flex-col gap-7 rounded-md py-8 p-6'>
                <div className='flex items-center gap-5'>
                    <div className='w-[48px] h-[48px]  bg-white rounded-full'>

                    </div>
                    <div className='text-[20px] font-semibold '>
                        Daniel Reynolds
                    </div>
                </div>
                <div className='max-w-[250px]'>
                    Dispatch Buddy has made my job significantly easier. I now have more time for the road, and I'm making more money. The best tool in my trucking arsenal.
                </div>
            </div>
        </div>
    </div>
  )
}
