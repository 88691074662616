import React from 'react'
import image1 from "../../img/Image1.png"
import image2 from "../../img/Image2.jpeg"
import image3 from "../../img/Image3.jpg"
import image4 from "../../img/Image4.png"
export const Features = () => {
  return (
    <div id='Features'  className='w-[1200px] mx-auto mt-14 min-h-[600px] flex flex-col gap-12 '>
    <div className=' bg-[#f2f2f2] rounded-md py-5 flex items-center justify-around '>
        <div className='bg-gradient-to-tr flex justify-center items-center object-contain from-gray-100 to-slate-300 rounded-md w-[380px] h-[420px]'>
            <img src={image1} alt=""/>
        </div>
        <div className='text-[18px] text-[#333] flex flex-col justify-around gap-5 '>
            <div  className='text-[36px] font-bold  '>
                Send emails to brokers with a single click.
            </div>
            <div  className='  max-w-[300px]'>
                Generate custom templates with dynamic variables and efficiently send personalized emails to brokers in large quantities. Benefit from multiple templates and personalized email signatures.
            </div>
        </div>            
    </div>
        <div className='bg-[#f2f2f2] rounded-md py-5 flex items-center justify-around '>
            <div className='text-[18px] max-w-[450px] text-[#333] flex flex-col justify-around'>
                <div  className='text-[36px] max-w-[450px] font-bold  '>
                    Automatically compute Revenue Per Mile (RPM).
                </div>
                <div  className='  max-w-[300px]'>
                    Analyze and dissect the cost per mile for any given load, making it simple to filter and identify the most lucrative options directly on the load board.
                </div>
            </div>   
            <div className='bg-gradient-to-tr from-gray-100 object-contain flex justify-center items-center to-slate-300  rounded-md w-[380px] h-[420px]'>
                <img src={image2} alt=""/>
            </div>         
        </div>
        <div className='bg-[#f2f2f2] rounded-md py-5 flex items-center justify-around '>
            <div className='bg-gradient-to-tr from-gray-100 to-slate-300 object-contain flex justify-center items-center rounded-md w-[380px] h-[420px]'>
                <img src={image3} alt=""/>
            </div>
            <div className='text-[18px] text-[#333] flex flex-col justify-around'>
                <div  className='text-[36px] font-bold max-w-[450px] '>
                    Access Google Maps integrated with load-specific information.
                </div>
                <div  className='  max-w-[300px]'>
                    Effortlessly access the load route on Google Maps while obtaining all essential load details, including truck location, pickup origin, delivery destination, and tool information.
                </div>
            </div>            
        </div>
        <div className='bg-[#f2f2f2] rounded-md py-5 flex items-center justify-around '>
            <div className='text-[18px] max-w-[450px] text-[#333] flex flex-col justify-around'>
                <div  className='text-[36px] max-w-[450px] font-bold  '>
                    Report brokers who are unreliable.
                </div>
                <div  className='  max-w-[300px]'>
                    Read reviews about brokers and share your experiences to safeguard fellow dispatchers from fraudulent and untrustworthy companies.
                </div>
            </div>
            <div className='bg-gradient-to-tr from-gray-100 to-slate-300 object-contain flex items-center justify-center rounded-md w-[380px] h-[420px]'>
                <img src={image4} alt=""/>
            </div>
        </div>

    </div>
  )
}
