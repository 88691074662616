import React from 'react'

export const LeadForm = () => {
  return (
    <div className='w-[980px] mx-auto h-[480px] rounded-3xl bg-gradient-to-tr from-gray-400 to-slate-600 text-white flex gap-5 flex-col items-center justify-center'>
        <div className='text-[36px] font-bold'>
            Wanna try with Dispatch Buddy
        </div>
        <div className='max-w-[480px]'>
            Register and commence emailing brokers and securing load bookings more rapidly!
        </div>
        <button className='text-slate-600 bg-white h-[45px] rounded-full w-[175px] '>
            Start free
        </button>
    </div>
  )
}
