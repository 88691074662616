import React from 'react'
import { NavLink } from "react-router-dom"
export const Foother = () => {
    return (
        <div  className='w-[1200px] text-[#444] min-h-[280px] mx-auto flex  justify-around  border-t border-slate-500 border-solid'>
            <div className='flex flex-col p-10 text-[21px] gap-5'>
                <div className='text-[28px] font-bold'>
                    Logo
                </div>
                <div>
                    @mail.com
                </div>
                <div>
                    +380 XX XX XX XXX
                </div>
            </div>            
            <div className='flex flex-col  p-10 text-[18px] gap-3'>
                <div className='font-bold'>
                    Explore
                </div>
                <NavLink className='no-underline hover:text-slate-600' to={'/#Features'}>
                    Features
                </NavLink>
                <NavLink className='no-underline hover:text-slate-600' to={'/#Product'}>
                    Product
                </NavLink>
                <NavLink className='no-underline hover:text-slate-600' to={'/#Pricing'}>
                    Pricing
                </NavLink>
            </div>            
            <div className='flex flex-col  p-10 text-[18px] gap-3'>
                <div className='font-bold'>
                    Company
                </div>
                <NavLink className='no-underline hover:text-slate-600' to={'/contact'}>
                    Contact Us
                </NavLink>
                <NavLink to={'/'} className='no-underline hover:text-slate-600'>
                    Privacy policy
                </NavLink>
            </div>
        </div>
    )
}
