import React from 'react'
import imagepromo from "../../img/ImagePromo.png"
export const PromoBlock = () => {
  return (
    <div id='Promo' className='w-[1200px] mx-auto h-[600px] flex items-center justify-between '>
        <div className='text-[18px] text-[#333] flex flex-col gap-5 '>
            <div className='text-[36px] font-bold max-w-[650px] '>
                The quickest method for securing additional freight bookings.
            </div>
            <div className='  max-w-[300px]'>
                Send an email to brokers, calculate revenue per mile (RPM), access Google Maps, and file reports on unreliable brokers using Dispatch Buddy.
            </div>
            <div className='flex gap-4 font-semibold'>
                <button className='bg-gradient-to-tr from-gray-400 to-slate-600 text-white h-[45px] rounded-md w-[175px] '>
                    Start free
                </button>
            </div>
        </div>
        <div className='bg-gradient-to-tr from-gray-100 to-slate-300 object-contain flex justify-center items-center rounded-md w-[560px] h-[480px]'>
            <img className='p-3' alt='' src={imagepromo}/>
        </div>
    </div>
  )
}
