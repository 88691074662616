import React from 'react'
import { NavLink } from 'react-router-dom'

export const Header = () => {
    return (
        <div className="w-[80vw] font-bold text-[#444] mx-auto h-[80px] flex justify-between text-[19px] p-3">
            <NavLink to={'/#Promo'} className='no-underline text-slate-600'>
                LOGO
            </NavLink>
            <NavLink className='no-underline hover:text-slate-600' to={'/#Features'}>
                Features
            </NavLink>
            <NavLink className='no-underline hover:text-slate-600' to={'/#Product'}>
                Product
            </NavLink>
            <NavLink className='no-underline hover:text-slate-600' to={'/#Pricing'}>
                Pricing
            </NavLink>
            <NavLink className='no-underline hover:text-slate-600' to={'/contact'}>
                Contact Us
            </NavLink>
        </div>
    )
}
