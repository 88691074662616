import React from 'react'

export const Pricing = () => {
    
  return (
    <div id='Pricing' className='w-[1200px] text-[#444] mx-auto mt-14 min-h-[600px] flex flex-col items-center '>
        <div className='text-[36px] font-bold '>
            Cost-effective pricing.
            
        </div>
        <div className='flex gap-8 mt-5'>
            <div className='flex flex-col gap-3 items-center'>
                <div className='text-[21px] font-semibold'>
                    Basic
                </div>
                <div className='flex items-center '>
                     <div className='text-[36px] text-black font-semibold'>$15</div>/per mounth
                </div>
                <button className='bg-gradient-to-tr from-gray-400 to-slate-600  text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                    Get started
                </button>
                <ul id='price' className='list-disc list-inside flex flex-col gap-2 text-[18px] font-semibold '>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                       text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text                  
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                </ul>
            </div>
            <div className='flex flex-col gap-3 items-center'>
                <div className='text-[21px] font-semibold'>
                    Basic
                </div>
                <div className='flex items-center '>
                     <div className='text-[36px] text-black font-semibold'>$15</div>/per mounth
                </div>
                <button className='bg-gradient-to-tr from-gray-400 to-slate-600  text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                    Get started
                </button>
                <ul className='list-disc list-inside flex flex-col gap-2 text-[18px] font-semibold '>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                       text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text                  
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                </ul>
            </div>
            <div className='flex flex-col gap-3 items-center'>
                <div className='text-[21px] font-semibold'>
                    Basic
                </div>
                <div className='flex items-center '>
                     <div className='text-[36px] text-black font-semibold'>$15</div>/per mounth
                </div>
                <button className='bg-gradient-to-tr from-gray-400 to-slate-600  text-white text-[20px] font-semibold w-[210px] h-[40px] rounded-lg'>
                    Get started
                </button>
                <ul id='price' className='list-disc list-inside flex flex-col gap-2 text-[18px] font-semibold '>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                       text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text                  
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                    <li className='px-2 w-[280px] h-[45px] rounded-md'>
                        text text text text text text 
                    </li>
                </ul>
            </div>

        </div>
    </div>
  )
}
