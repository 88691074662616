import React from 'react'

export const Contact = () => {
  return (
    <div className='w-[1200px] bg-gradient-to-tr mb-10 from-gray-400 text-lg justify-center to-slate-600 rounded-xl text-white mx-auto mt-14 min-h-[600px] flex items-center gap-12 '>
        <div className='flex flex-col p-10  gap-3 '>
            <div className='text-[36px] font-bold'>
                Contact  Us
            </div>
            <div className='max-w-[380px]'>
                Email, call, or fill out the form to discover how we can assist your trucking business.
            </div>
            <div>
                @mail.com
            </div>
            <div>
                +380 XX XX XX XXX
            </div>
        </div>
        <form className='bg-white text-[#999] flex flex-col items-center gap-3 rounded-xl p-5 min-h-[350px] w-[560px]'>
            <div className='text-[26px] text-[#444] font-bold'>
                We're thrilled to be of assistance!
            </div>
            <div className='max-w-[450px] text-sm mx-[65px]'>
                Kindly provide some information, and we will get in touch with you shortly.
            </div>
            <div className='flex justify-between gap-5'>
                <input type="text" placeholder='First name' className='outline-none border border-solid  border-slate-400 rounded-md p-2 max-h-[45px] focus:border-green-400'/>
                <input type="text" placeholder='Last name' className='outline-none border border-solid border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
            </div>
            <input type="email" placeholder='Email' className='outline-none border w-[480px] border-solid border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
            <input type="text" placeholder='Web site' className='outline-none border w-[480px] border-solid border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
            <input type="text" placeholder='Numbers of dispatchers' className='outline-none border w-[480px] border-solid border-slate-400 rounded-md p-2 max-h-[45px]  focus:border-green-400' />
            <textarea  placeholder='How we can help?' className='outline-none  resize-none border w-[480px] border-solid border-slate-400 rounded-md p-2 h-[150px]  focus:border-green-400' />
            <button className='w-[480px] h-[45px] transition-all bg-gradient-to-tr from-gray-400 text-lg justify-center to-slate-600 hover:to-slate-400 rounded-xl text-white  focus:border-green-400'>
                Submit
            </button>
        </form>
    </div>
  )
}
