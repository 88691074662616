import React from 'react'

export const Product = () => {
  return (
    <div id='Product'  className='w-[1200px] text-[#444] bg-[#f2f2f2] rounded-2xl py-10 px-10 mx-auto mt-14 min-h-[450px] flex flex-col gap-5 items-center '>
        <div className='text-[36px] font-bold max-w-[950px] '>
            Effortlessly secure freight bookings with just a single click!
        </div>
        <div className='flex justify-around gap-14'>
            <div className='flex flex-col gap-5'>
                <div className='text-[36px] w-[250px]  font-bold'>
                    Achieve tasks at a speed ten times faster.
                </div>
                <div className='text-[18px] w-[250px]  max-w-[200px] '>
                    In your first month of use, you can significantly increase your truck's load bookings.
                </div>
            </div>
            <div className='flex flex-col gap-5'>
                <div className='text-[36px] w-[250px]   font-bold'>
                    A user base of 5,000 individuals.
                </div>
                <div className='text-[18px] w-[250px]  max-w-[200px] '>
                    Joined and have been actively utilizing our extension on a daily basis in just a matter of months.
                </div>
            </div>
            <div className='flex flex-col gap-5'>
                <div className='text-[36px] w-[250px]   font-bold'>
                    In a mere three minutes.
                </div>
                <div className='text-[18px] w-[250px]  max-w-[200px] '>
                    To start things off and see how it benefits your trucking business
                </div>
            </div>
        </div>
        <div className='w-[980px] h-[120px] bg-gradient-to-tr from-gray-400 to-slate-600  rounded-3xl flex items-center justify-around'>
            <div className='text-[32px] font-bold text-white'>
                Would you like to understand how it operates?
            </div>
            <button className='text-gradient-to-tr from-gray-400 to-slate-600  bg-white font-semibold rounded-full w-[160px] h-[45px]'>
                Watch promo
            </button>

        </div>
    </div>
  )
}
