import React from 'react';
import { Route,Routes } from 'react-router-dom'
import {Header} from "./components/Header/Header";
import {Foother} from "./components/Foother/Foother";
import { PromoBlock } from './components/FromoBlock/PromoBlock';
import { Features } from './components/Features/Features';
import { Product } from './components/Product/Product';
import { Pricing } from './components/Pricing/Pricing';
import { Comments } from './components/Comments/Comments';
import { LeadForm } from './components/LeadForm/LeadForm';
import { Contact } from './components/Contact/Contact';

function App() {
  return (
    <div className='bg-gray-50 w-screen min-h-screen flex flex-col justify-between'>

      <Header/>
      <Routes>
        <Route path='/' element={<><PromoBlock/><Product/><Features/>
        <div className='w-[1200px] mt-5 mx-auto flex flex-col items-center'>
          <div className='text-[21px] text-[#333] font-bold'>Dispatch Buddy collaborates with the most extensive load boards.</div>
          <div className='flex gap-12 mt-3'>
              <div className='w-[140px] h-[35px] bg-gray-300 rounded-md'></div>
              <div className='w-[140px] h-[35px] bg-gray-300 rounded-md'></div>
              <div className='w-[140px] h-[35px] bg-gray-300 rounded-md'></div>
              <div className='w-[140px] h-[35px] bg-gray-300 rounded-md'></div>
              <div className='w-[140px] h-[35px] bg-gray-300 rounded-md'></div>
              <div className='w-[140px] h-[35px] bg-gray-300 rounded-md'></div>
          </div>
        </div>
      <Pricing/>
      <LeadForm/>
          <Comments/></>}/>
        <Route path='/contact' element={<Contact/>}/>
      </Routes>

      <Foother/>

    </div>
  );
}

export default App;
